* {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  font-family: sans-serif;
  text-align: center;
}

